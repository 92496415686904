<template>
  <div>
    <div class="d-flex align-center pa-4">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        class="mr-2"
        @click="mutateSide"
      ></v-app-bar-nav-icon>
      <breadcrumbs :items="items" />
    </div>
    <div class="px-5">
      <h2>Pendaftaran</h2>
      <v-row v-if="!loading && dataSet" no-gutters>
        <v-col cols="12" md="7" class="pa-2">
          <div v-for="(item, n) in dataSet" :key="n">
            <div
              v-if="
                item.label != 'Kebutuhan Layanan' &&
                item.label != 'Jangka Waktu'
              "
              :class="n != 0 ? 'mt-3' : ''"
            >
              <h4>{{ item.label }}</h4>
              <v-text-field
                class="rounded-lg"
                outlined
                :placeholder="`Silakan isi ${item.label} disini`"
                v-model="item.value"
                color="#0D47A1"
                dense
                hide-details
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <v-col
          v-if="info_layanan"
          cols="12"
          md="5"
          class="pa-2"
          style="display: grid"
        >
          <v-card outlined class="pa-3">
            <h3>Info Layanan</h3>
            <div v-for="(item, n) in dataSet" :key="n">
              <div v-if="item.label == 'Kebutuhan Layanan'">
                <div>
                  <h4>{{ item.label }}</h4>
                  <v-text-field
                    class="rounded-lg"
                    outlined
                    :placeholder="`Silakan isi ${item.label} disini`"
                    v-model="item.value"
                    color="#0D47A1"
                    dense
                    hide-details
                  ></v-text-field>
                </div>
              </div>
              <div v-if="item.label == 'Jangka Waktu'" class="mt-3">
                <h4>{{ item.label }}</h4>
                <v-menu
                  v-model="menuJangka_waktu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="item.value"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Silakan isi tanggal lahir di sini"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="item.value" range>
                    <div
                      style="width: 100%"
                      class="d-flex align-center justify-end mt-n7 mb-2 px-3"
                    >
                      <v-btn color="error" @click="menuJangka_waktu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        class="ml-2"
                        color="bg_color1"
                        dark
                        @click="closeDateMenu(item.value, n)"
                      >
                        OK
                      </v-btn>
                    </div>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="loading" no-gutters>
        <v-col cols="12" md="7" class="pa-2">
          <div v-for="(item, n) in 3" :key="n">
            <div :class="n != 0 ? 'mt-3' : ''">
              <v-skeleton-loader
                class="rounded-lg"
                width="90px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="rounded-lg mt-1"
                width="100%"
                height="40px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="pa-2">
          <div v-for="(item, n) in 2" :key="n">
            <div :class="n != 0 ? 'mt-3' : ''">
              <v-skeleton-loader
                class="rounded-lg"
                width="90px"
                height="20px"
                type="image"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="rounded-lg mt-1"
                width="100%"
                height="40px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex align-center justify-end pa-2">
        <v-btn
          depressed
          outlined
          color="#BD9028"
          class="text-capitalize mr-3"
          dark
          @click="reset"
        >
          <h4>Reset</h4>
        </v-btn>
        <v-btn
          :disabled="loading_save ? true : false"
          depressed
          class="text-capitalize bg_default"
          @click="registrasi"
        >
          <h4 class="txt_white" v-if="!loading_save">Save</h4>
          <h4 class="txt_white" v-if="loading_save">Loading</h4>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import breadcrumbs from "../../components/breadcrumbs/breadcrumbs.vue";
export default {
  components: { breadcrumbs },
  name: "registrasiClient",
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
    }),
  },
  data() {
    return {
      loading: true,
      loading_save: false,
      toggler: true,
      dataSet: null,
      info_layanan: false,
      klien_id: "",
      field: {
        nama_instasi: "",
        nama: "",
        gelar: "",
        jabatan: "",
        kebutuhan: "",
        jangka_waktu: "",
      },
      menuJangka_waktu: false,
      items: [
        {
          text: "Pendaftaran",
          disabled: false,
          href: "/registrasi/client",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `registration/customer/${this.$route.params.idRegistration}`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          let tempData = [];
          this.klien_id = data.klien.id;
          data.data.forEach((item) => {
            if (
              item.label == "Jangka Waktu" ||
              item.label == "Kebutuhan Layanan"
            ) {
              this.info_layanan = true;
            }
            tempData.push({
              attribute: item.attribute,
              label: item.label,
              deleted: item.deleted,
              id: item.id,
              status: item.status,
              type: item.type,
              tipe: item.tipe,
              value: "",
              validator: item.validator,
            });
          });
          this.dataSet = tempData;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    mutateSide() {
      if (this.toggler == this.drawer) {
        this.toggler = !this.toggler;
      }
      this.$store.commit("MUTATE_DRAWER", this.toggler);
    },
    async registrasi() {
      this.loading_save = true;
      let body = {
        klien_id: this.klien_id,
      };
      let custom_field = [];
      this.dataSet.forEach((item) => {
        if (item.label == "Jangka Waktu") {
          if (item.value.length > 1) {
            body[item.attribute] = `${this.$date(item.value[0]).format(
              "YYYY/MM/DD"
            )}-${this.$date(item.value[1]).format("YYYY/MM/DD")}`;
          } else {
            body[item.attribute] = item.value;
          }
        } else {
          if (item.attribute != null) {
            body[item.attribute] = item.value;
          } else {
            custom_field.push({
              [item.label]: item.value,
            });
          }
        }
      });
      body["custom_profile"] = JSON.stringify(custom_field);

      let data = {
        body: body,
        path: `sysadmin/registration/save`,
      };
      this.$store
        .dispatch("auth/registrasi_customer", data)
        .then((data) => {
          this.loading_save = false;
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          this.dataSet.forEach((item) => {
            item.value = "";
          });
          if (data.code == 201) {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading_save = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
    reset() {
      this.dataSet.forEach((item) => {
        item.value = "";
      });
    },
    closeDateMenu(item, idx) {
      this.menuJangka_waktu = false;
      this.dataSet[idx].value == ""
        ? this.$date(item).format("dddd, MMMM Do YYYY")
        : "";
    },
  },
};
</script>

<style></style>
